<template>
    <v-form ref="form" @submit.prevent="clickSave">
        <v-card v-model="formHasErrors" lazy-validation>
            <v-card-title class="cms_modal__title">
                <span class="text-h6">{{ dialogEdit ? $t('action.edit_company') : $t('action.new_company') }}</span>
                <button type="button" class="btn-close" @click="clickCancel">
                    <v-icon class="fa-solid fa-xmark"></v-icon>
                </button>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                ref="Company Code"
                                v-model="cuItem.company_code"
                                :label="$t('form.company_code')"
                                :counter="255"
                                maxlength="255"
                                :rules="[requiredRule]"
                                required
                            ></v-text-field>
                            <v-text-field
                                ref="Company Name"
                                v-model="cuItem.company_name"
                                :label="$t('form.company_name')"
                                :counter="255"
                                maxlength="255"
                                :rules="[requiredRule]"
                                required
                            ></v-text-field>
                            <v-text-field
                                ref="Email"
                                v-model="cuItem.email"
                                :label="$t('form.email')"
                                :disabled="dialogEdit"
                                :rules="[requiredRule, emailRule]"
                                required
                            ></v-text-field>
                            <v-text-field
                                ref="Department"
                                v-model="cuItem.department"
                                :label="$t('form.department')"
                            ></v-text-field>
                            <v-text-field
                                ref="Zipcode"
                                v-model="cuItem.zipcode"
                                :label="$t('form.zipcode')"
                            ></v-text-field>
                            <!-- <v-select
                                ref="Status"
                                v-model="cuItem.status"
                                :label="$t('form.status')"
                                :items="itemsStatus"
                            ></v-select> -->
                            <v-textarea
                                auto-grow
                                outlined
                                rows="4"
                                row-height="15"
                                label="解説"
                                v-model="cuItem.note"
                                ref="Note"
                                class="mt-3"
                            ></v-textarea>

                            <!-- <v-text-field ref="Password" v-model="cuItem.password" :label="$t('form.password')"
                                :disabled="dialogEdit" :rules="[passwordRule('')]" :counter="20" maxlength="20"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'"
                                @click:append="show1 = !show1" required v-if="!dialogEdit"></v-text-field> -->
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions class="cms_modal__actions justify-end">
                <v-btn type="button" color="blue darken-1" text @click="clickCancel"> {{ $t('cancel') }} </v-btn>
                <v-btn type="button" color="blue darken-1" text @click="clickSave"> {{ $t('form.save') }} </v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import { mixinRules } from '../../../mixins/rules';

export default {
    mixins: [mixinRules],
    components: {},
    props: {
        cuItem: {
            type: Object,
        },
        formTitle: {
            type: String,
        },
        closeCU: { type: Function },
        save: { type: Function },
        propsField: { type: Array },
        dialogEdit: { type: Boolean },
    },
    data() {
        return {
            itemsStatus: [
                { text: this.$t('form.approved'), value: 'APPROVED' },
                { text: this.$t('form.rejected'), value: 'REJECTED' },
                { text: this.$t('form.waitting'), value: 'WAITING' },
            ],
            selectTypeSection: null,
            errorMessages: '',
            formHasErrors: false,
            show1: false,
            imageState: null,
            noImage: require('../../../assets/images/no_image.jpg'),
        };
    },
    computed: {
        form() {
            return {
                company_code: this.cuItem.company_code,
                company_name: this.cuItem.company_name,
                email: this.cuItem.email,
                department: this.cuItem.department,
                zipcode: this.cuItem.zipcode,
                status: this.cuItem.status,
                note: this.cuItem.note,
            };
        },
    },
    watch: {},
    methods: {
        validate() {
            this.formHasErrors = false;

            Object.keys(this.form).forEach((f) => {
                if (!this.form[f]) this.formHasErrors = true;
                this.$refs[f].validate(true);
            });
        },

        resetForm() {
            this.errorMessages = [];
            this.formHasErrors = false;

            Object.keys(this.form).forEach((f) => {
                this.$refs.form.resetValidation();
            });
        },

        async clickSave() {
            const isValid = this.$refs.form.validate();
            if (isValid) {
                this.save((isSuccess) => {
                    if (isSuccess) this.clickCancel();
                });
            }
        },

        clickCancel() {
            this.closeCU();
            this.resetForm();
        },
    },
};
</script>

<style lang="scss">
.modal__title {
    display: flex;
    justify-content: space-between;
    line-height: 1;
    .btn-close i {
        color: #aaa !important;
        transition: all 0.4s ease;
    }
    .btn-close:hover i {
        color: #000 !important;
    }
}
</style>
